<template>


    <div class="wrapper">
        <section class="encabezado -contacto">
            <h1>Contacto</h1>
        </section>

        <section class="contenedor-contacto container row-center ais">

            <main class="formulario">
                <formulario></formulario>
            </main>

            <aside class="contacto-info column-center">

                <img src="/img/logo-uc3m-alumni-dark.png" alt="Uc3m Alumni">

                <p class="direccion">
                    <span>
                        Universidad Carlos III de Madrid Campus de Getafe.
                        Despacho 15.0.03
                    </span>
                    <span>Edificio López Aranguren</span>
                    <span>28903 Getafe - Madrid</span>
                </p>

                <p class="telefono"><a href="tel:916248700">91 624 87 00</a></p>

                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3042.14609821742!2d-3.7275104173951856!3d40.31691500175958!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd4220b90a840de7%3A0x8bc24e4eab60c842!2sUniversidad%20Carlos%20III%20de%20Madrid%3A%20Campus%20de%20Getafe!5e0!3m2!1ses!2ses!4v1612975731087!5m2!1ses!2ses" width="100%" height="326" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>

            </aside>

        </section>


    </div>

</template>

<script>

    import formulario from '@/components/formulario-contacto'

    export default{
        name : 'contacto',
        components : {
            'formulario' : formulario 
        }
    }
</script>